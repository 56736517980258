export const realtorData = {
  email: 'valorpro@unne.cl',
  phone:'56989205753'
  // email: 'contacto@accionpanal.com',
};

export const footerData = {
  email: 'valorpro@unne.cl',
  phone:'989205753',
  wsp:'989205753',
}
